import { useQuery } from '@tanstack/react-query';

import { getAllPermissions, getClaimsReviewers, getCurrentUser, getMlpUsers } from 'api';

export const getCurrentUserQueryKey = ['get-current-user'];
export const getAllReviewersQueryKey = ['get-all-reviewers'];
export const getMlpUsersQueryKey = ['get-mlp-users'];
export const getPermissionsQueryKey = ['get-permissions'];

export const useCurrentUserQuery = (overrides = {}) =>
  useQuery({
    queryKey: getCurrentUserQueryKey,
    queryFn: getCurrentUser,
    staleTime: Infinity,
    ...overrides,
  });

export const useAllReviewersQuery = (overrides = {}) =>
  useQuery({
    queryKey: getAllReviewersQueryKey,
    queryFn: getClaimsReviewers,
    ...overrides,
  });

export const usePermissionsQuery = (payload = {}, overrides = {}) =>
  useQuery({
    queryKey: getPermissionsQueryKey.concat(payload),
    queryFn: () => getAllPermissions(payload),
    ...overrides,
  });

export const useMlpUsersQuery = (overrides = {}) =>
  useQuery({
    queryKey: getMlpUsersQueryKey,
    queryFn: getMlpUsers,
    ...overrides,
  });
