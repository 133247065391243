// To display the date correctly, we need to parse the string into Date and get rid of '-'. See https://stackoverflow.com/a/41125840
export const convertDateStringToDate = dateField => {
  // if the dateField is already a date, just return it
  if (dateField instanceof Date) {
    return dateField;
  }
  if (typeof dateField !== 'string' || !dateField || !Date.parse(dateField)) {
    return null;
  }

  const dateCompatibleAllBrowsers = dateField.slice(0, 10).replace(/-/g, '/');
  return new Date(dateCompatibleAllBrowsers);
};

export const convertDateToDateString = (date, includeTime = false) => {
  if (date === null) return '';
  if (date instanceof Date) {
    if (includeTime) return date.toISOString().slice(0, 16);
    return date.toISOString().slice(0, 10);
  }
  return date;
};

// Convert a YYYY-MM-DDTHH:MM:SS.SZ string to a MM/DD/YYYY string
export const convertDateTimeToDate = dateField => {
  if (typeof dateField !== 'string' || !dateField || !Date.parse(dateField)) {
    return null;
  }
  const dateArray = dateField.split('T', 1)[0].split('-');
  dateArray.push(dateArray.shift());
  return dateArray.join('/');
};

export const getPayPeriods = () => {
  // get the pay periods for the past 6 months. Payment is bi-monthly.
  const now = new Date();
  const payPeriods = [
    [
      new Date(now.getFullYear(), now.getMonth(), 1),
      new Date(now.getFullYear(), now.getMonth(), 15),
    ],
  ];
  if (now.getDate() >= 16)
    payPeriods.unshift([
      new Date(now.getFullYear(), now.getMonth(), 16),
      new Date(now.getFullYear(), now.getMonth() + 1, 0),
    ]);
  for (let i = 1; i < 6; i++) {
    payPeriods.push([
      new Date(now.getFullYear(), now.getMonth() - i, 16),
      new Date(now.getFullYear(), now.getMonth() - i + 1, 0),
    ]);
    payPeriods.push([
      new Date(now.getFullYear(), now.getMonth() - i, 1),
      new Date(now.getFullYear(), now.getMonth() - i, 15),
    ]);
  }
  return payPeriods.map(payPeriod =>
    payPeriod.map(payDate => convertDateToDateString(payDate)).join(' to '),
  );
};

export const getOutOfNetworkPayPeriods = () => {
  // get the pay periods for the past 6 months. Payment is weekly.
  const now = new Date();
  const payPeriods = [
    [
      new Date(now.getFullYear(), now.getMonth(), 1),
      new Date(now.getFullYear(), now.getMonth(), 7),
    ],
  ];
  if (now.getDate() >= 8)
    payPeriods.unshift([
      new Date(now.getFullYear(), now.getMonth(), 8),
      new Date(now.getFullYear(), now.getMonth(), 15),
    ]);
  if (now.getDate() >= 16)
    payPeriods.unshift([
      new Date(now.getFullYear(), now.getMonth(), 16),
      new Date(now.getFullYear(), now.getMonth(), 23),
    ]);
  if (now.getDate() >= 24)
    payPeriods.unshift([
      new Date(now.getFullYear(), now.getMonth(), 24),
      new Date(now.getFullYear(), now.getMonth() + 1, 0),
    ]);
  for (let i = 1; i < 6; i++) {
    payPeriods.push([
      new Date(now.getFullYear(), now.getMonth() - i, 24),
      new Date(now.getFullYear(), now.getMonth() - i + 1, 0),
    ]);
    payPeriods.push([
      new Date(now.getFullYear(), now.getMonth() - i, 16),
      new Date(now.getFullYear(), now.getMonth() - i, 23),
    ]);
    payPeriods.push([
      new Date(now.getFullYear(), now.getMonth() - i, 8),
      new Date(now.getFullYear(), now.getMonth() - i, 15),
    ]);
    payPeriods.push([
      new Date(now.getFullYear(), now.getMonth() - i, 1),
      new Date(now.getFullYear(), now.getMonth() - i, 7),
    ]);
  }
  return payPeriods.map(payPeriod =>
    payPeriod.map(payDate => convertDateToDateString(payDate)).join(' to '),
  );
};

export const getPayPeriodOptions = inNetwork => {
  if (inNetwork) return getPayPeriods();
  return getOutOfNetworkPayPeriods();
};

export const isDateToday = date => {
  const today = new Date();
  return date.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0);
};
