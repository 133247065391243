import client, { CLIENT_GET_FILE, CLIENT_POST_FILE } from 'api/client';

import createURLParams from 'utils/urls';

export const addMlpUser = payload => client.post('users/mlp-users/', payload);

export const getMlpUsers = async () => {
  const { data } = await client.get('users/mlp-users/');
  return data;
};

export const getPanelReps = () => client.get('users/mlp-users/panel-reps/');

export const getMlpUser = pk => client.get(`users/mlp-users/${pk}/`);

export const findAndUpdateMlpUser = (id, payload) =>
  client.patch(`users/mlp-users/${id}/`, payload);

export const updatePanelProfilePicture = payload =>
  CLIENT_POST_FILE.post('users/mlp-users/update-panel-rep-photo/', payload);

export const getPanelProfilePicture = pk =>
  CLIENT_GET_FILE.get(`users/mlp-users/${pk}/panel-rep-photo/`);

export const updateMiscDocument = payload => {
  return CLIENT_POST_FILE.post('shared/misc-documents/update-misc-document/', payload);
};

export const getMiscDocument = name => {
  const params = createURLParams({ filename: name });
  return CLIENT_GET_FILE.get(`shared/misc-documents/get-misc-document/`, { params });
};
