import client from 'api/client';

export const getMemos = () => client.get('memos/');

export const getVisibleMemos = () => client.get(`memos/visible/`);

export const deleteMemo = async memoId => {
  const { data } = await client.delete(`memos/${memoId}/`);
  return data;
};

export const createMemo = async payload => {
  const { data } = await client.post(`memos/`, payload);
  return data;
};

export const findAndUpdateMemo = async payload => {
  const { data } = await client.patch(`memos/${payload.id}/`, payload);
  return data;
};
